// TODO: separate GA4 utils with PMT-Tracking utils
import { type IParcel, ParcelStatus } from '@/modules/pmt/interfaces';

declare global {
  interface Window {
    dataLayer: any;
    google_tag_manager: any;
  }
}

// TODO: delete this function or the 1st line inside pushEvent() function
export const resetGTMDataLayer = (): void => {
  // window.google_tag_manager[publicRuntimeConfig.GTM_ID]?.dataLayer?.reset()
  // .reset() is dangerous since it's deleting the whole dataLayer, including the gtm.start event, and others
  window.dataLayer = window.dataLayer || [];
};

export const pushEvent = (eventName: string, details?: Record<string, any>): void => {
  window.dataLayer = window.dataLayer || []; // for event not using resetGTMDataLayer()

  if (window.google_tag_manager) {
    // Google Tag Manager has already been loaded
    window.dataLayer.push({
      event: eventName,
      ...details,
    });
  } else {
    window.addEventListener('gtm_loaded', () => {
      // Google Tag Manager has been loaded
      window.dataLayer.push({
        event: eventName,
        ...details,
      });
    });
  }
};

export const resetDataLayerAndPushEvent: typeof pushEvent = (eventName, details = undefined): void => {
  resetGTMDataLayer();
  pushEvent(eventName, details);
};

// PMT
const chipModify = (chip: string) => {
  if (!chip.length) {
    return chip;
  }
  const invalidChar = /[^-a-zA-Z0-9_.]+/g;
  const modifiedText = chip.replace(invalidChar, '');
  return modifiedText.substring(0, 50).toUpperCase();
};

export const trackingInputModify = (text?: string): string[] | undefined => {
  if (!text) return;
  const chips = text.split(/[,:; ]+/);

  if (chips.length === 1) {
    return;
  }

  return chips.map((chip: string) => chipModify(chip)).filter((chip: string) => !!chip);
};

export const trackingInputPasteModify = (text: string): string[] | undefined => {
  const chips = text
    .split(/[,:; ]+/)
    .map((chip: string) => chipModify(chip))
    .filter((chip: string) => !!chip);

  if (!chips.length) {
    return;
  }

  return chips;
};

const MAX_CHIP = 5;
const PARAM_SEPARATOR = '~';

export const trackingParamExtractionModify = (parcelIdsStr: string): string[] => {
  const parcelIds = parcelIdsStr
    .split(PARAM_SEPARATOR)
    .map((parcelId) => chipModify(parcelId))
    .filter((parcelId) => !!parcelId);

  return Array.from(new Set(parcelIds)).slice(0, MAX_CHIP);
};

export const checkParcelNotFound = (parcel: IParcel) => {
  return parcel.status === ParcelStatus.NotFound || parcel.events?.length === 0;
};

export const checkPmtSingleQueryParamValid = (query?: Record<string, any>, queryValue?: string | string[]) => {
  if (!query || !queryValue) return false;
  const containsOneParamInEngPage = Object.keys(query).length === 1;
  const containsOneParamInLangPage = Object.keys(query).length === 2 && query['articleTypeSlug'];
  const containsOneParam = containsOneParamInEngPage || containsOneParamInLangPage;

  return !!containsOneParam;
};

export const checkPmtEmailQueryValid = (query?: Record<string, any>, queryValue?: string | string[]) => {
  if (!query || !queryValue) return false;
  // shipmentId in url is not a string
  if (typeof queryValue !== 'string') return false;

  // shipmentId is empty
  if (trackingInputModify(queryValue)?.length === 0) return false;

  // https://www.pivotaltracker.com/n/projects/2649247/stories/185634092
  // only allow shipmentId, articleTypeSlug OR trigger in url
  switch (Object.keys(query).length) {
    case 1:
      return 'shipmentId' in query;
    case 2:
      return 'shipmentId' in query && ('articleTypeSlug' in query || 'trigger' in query);
    case 3:
      return 'shipmentId' in query && 'articleTypeSlug' in query && 'trigger' in query;
    default:
      return false;
  }
};

export const scrollIntoView = (elementId: string) => {
  document.getElementById(elementId)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
};
