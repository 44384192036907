import { PageOverviewBanner, SEOSection } from '@/components';

import { ContentTypeName } from '@/constants';
import { capitalize } from '@/utils';

import SectionListRenderer from '../SectionListRenderer';

interface IHomepageRendererProps {
  sectionList?: any[];
}

const HomepageRenderer: React.FC<IHomepageRendererProps> = ({ sectionList = [] }) => {
  const foundPageOverviewBanner = sectionList.find(
    (section: any) => capitalize(section.__typename) === ContentTypeName.PageOverviewBanner,
  );
  const foundSeoSection = sectionList.find(
    (section: any) => capitalize(section.__typename) === ContentTypeName.SeoSection,
  );
  const filteredSectionList = sectionList
    .filter((section: any) => section !== foundPageOverviewBanner)
    .filter((section: any) => section !== foundSeoSection);

  return (
    <>
      {foundPageOverviewBanner && <PageOverviewBanner priority {...foundPageOverviewBanner} />}
      <SectionListRenderer sectionList={filteredSectionList} />
      {foundSeoSection && <SEOSection {...foundSeoSection} />}
    </>
  );
};

export default HomepageRenderer;
