import { SectionWrapper, Typography } from '@/components';
import { styledText } from '@/components/Typography/Typography.styles';
import { TextStyles } from '@/constants';
import { LeadGenBannerVariant } from '@/interfaces';
import Image from 'next/image';
import styled from 'styled-components';
import { BreadcrumbsSectionContainer } from '../Breadcrumbs/Breadcrumbs.styles';
import { LeadGenBannerWrapper } from '../LeadGenBanner/LeadGenBanner.styles';

export const SectionListWrapper = styled.div`
  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-top: ${({ theme }) => theme.spacing[40]};
    padding-bottom: ${({ theme }) => theme.spacing[40]};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  ${BreadcrumbsSectionContainer} {
    margin: ${({ theme }) => `${theme.spacing[20]} ${theme.spacing[20]} ${theme.spacing[16]} ${theme.spacing[20]}`};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${BreadcrumbsSectionContainer} {
      margin: ${({ theme }) => `0 0 ${theme.spacing[16]} 0`};
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ theme }) => `
    padding-bottom: ${theme.spacing[20]};
    padding-left: ${theme.spacing[20]};
  `}

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: ${({ theme }) => theme.spacing[24]};
    padding: 0;
  }
`;

export const Title = styled(Typography).attrs({
  variant: TextStyles['Heading 2'],
  className: 'title',
  type: 'h1',
})`
  color: ${({ theme }) => theme.colors.highEmphasisBlack};
  padding-left: ${({ theme }) => theme.spacing[16]};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    ${styledText[TextStyles['Heading 1']]}
  }
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing[20]};
  padding-top: 0;

  ${TitleWrapper} {
    padding: 0;
    margin: 0;
  }

  ${TitleWrapper} ~ p {
    margin-top: ${({ theme }) => theme.spacing[16]};
  }

  ${Title} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-bottom: ${({ theme }) => theme.spacing[24]};
    padding: 0;

    & > ${TitleWrapper} ~ p {
      margin-top: ${({ theme }) => theme.spacing[8]};
    }
  }
`;

export const PageIcon = styled(Image).attrs({
  width: 32,
  height: 32,
})(({ theme }) => ({
  objectFit: 'contain',
  marginRight: theme.spacing[16],
}));

export const LeadGenBannerSection = styled(SectionWrapper)<{
  variant?: LeadGenBannerVariant;
  isHideLoggedInUser?: boolean;
}>`
  padding: 0;
  ${({ isHideLoggedInUser }) => isHideLoggedInUser && 'display: none;'}

  ${({ variant }) => (variant === LeadGenBannerVariant.FULL_WIDTH ? 'margin: 0 !important;' : '')}

  ${({ variant }) => (variant === LeadGenBannerVariant.FULL_WIDTH ? 'margin: 0 !important;' : '')}

  ${LeadGenBannerWrapper} {
    border-radius: 0;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    border: none !important;

    ${LeadGenBannerWrapper} {
      border-radius: 8px;
    }
  }
`;

export const LeftSection = styled.div`
  width: auto;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    flex: 0 0 736px;
    max-width: 736px;
  }
`;

export const RichTextContainer = styled.div`
  padding: ${({ theme }) => `${theme.spacing[40]} ${theme.spacing[20]}`};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: 64px 0;
  }
`;

export const IconWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  marginRight: theme.spacing[16],
}));
