import { ErrorMessages, MessageField } from '@/constants';
import getConfig from 'next/config';
import { useEffect, useRef } from 'react';
import type React from 'react';
import { AdsenseSectionWrapper } from './Adsense.styles';

const { publicRuntimeConfig } = getConfig();

declare global {
  interface Window {
    adsbygoogle: any;
  }
}

export interface AdsenseProps {
  id?: string;
  className?: string;
  dataAdSlot: string;
}

const Adsense: React.FC<AdsenseProps> = ({ id, className, dataAdSlot }) => {
  const adRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadAds = () => {
      try {
        if (typeof window !== 'undefined') {
          window.adsbygoogle = window.adsbygoogle || [];
          const dataAdsbygoogleStatus = adRef.current
            ?.getElementsByClassName('adsbygoogle')[0]
            .getAttribute('data-adsbygoogle-status');
          if (dataAdsbygoogleStatus === 'done') return;
          window.adsbygoogle.push({});
        }
      } catch (error: any) {
        console.error(ErrorMessages[MessageField.ADSENSE_ERROR], error.message);
      }
    };

    loadAds();
  }, []);

  return (
    <AdsenseSectionWrapper id={id} className={className} ref={adRef}>
      <ins
        className='adsbygoogle'
        data-ad-client={publicRuntimeConfig.googleAdsenseClient}
        data-ad-slot={dataAdSlot}
        style={{ display: 'block' }}
      />
    </AdsenseSectionWrapper>
  );
};

export default Adsense;
