import { ArticleTypeName, ContentTypeName, PageType } from '@/constants';

import {
  HighlightDisplayLogic,
  type IHighlightSection,
  type ISEOSimpleFields,
  type ISlugItem,
  type ITag,
  type IThumbnailCarousel,
  type IThumbnailSection,
  SectionItemType,
  type ThumbnailProps,
} from '@/interfaces';

import { rightSectionList, tagsAndMarketsQuery, thumbnailSectionQuery, wordCloudQuery } from '@/fetchers';
import { minifyQuery, tagDataModify } from '@/utils';
import {
  needEventOrArticleData,
  utils_getEventThumbnailsByDate,
  utils_getTagPageThumbnailSectionData,
  utils_getThumbnailsByTag,
  utils_getThumbnailsByTagList,
} from '../utils';
import callContentful from './base.fetchers';

const THUMBNAIL_CAROUSEL_MAX_ITEMS = 30;

type GetTag = (args: {
  isPreviewMode: boolean;
  articleTypeName?: string;
  slug?: string;
  allEventsList: ThumbnailProps[];
  allArticlesList: ThumbnailProps[];
}) => Promise<ITag | null>;

export const getTag: GetTag = async ({ isPreviewMode, articleTypeName, slug, allEventsList, allArticlesList }) => {
  const query = /* GraphQL */ `
    {
      tagCollection(
        preview: ${isPreviewMode}
        where: {
          ${articleTypeName ? `type: "${articleTypeName}"` : ''}
          ${slug ? `slug: "${slug}"` : 'slug_exists: false'}
        }
        limit: 1
      ) {
        items {
          sys { id }
          title
          breadcrumbsTheme
          type
          slug
          hide
          seo {
            title
            description
            ogType
            ogUrl
            ogImage {
              contentType
              url
            }
            ogTitle
            ogDescription
            robots
          }
          ${rightSectionList(isPreviewMode)}
          sectionList: sectionListCollection(
            preview: ${isPreviewMode}
            limit: 12
          ) {
            items {
              __typename
              ... on HighlightSection {
                title
                displayingLogic
                tags: tagsCollection(limit: 5) {
                  items {
                    sys {
                      id
                    }
                    title
                    type
                    slug
                  }
                }
              }
              ${wordCloudQuery()}
              ... on SeoSection {
                title
                seoList
                width
                longForm {
                  json
                }
              }
              ... on ThumbnailCarousel {
                tag {
                  title
                  type
                  slug
                  hide
                }
                itemType
              }
              ... on ThumbnailSection {
                ${thumbnailSectionQuery()}
              }
              ... on LeadGenBanner {
                title
                titleMobile
                image {
                  contentType
                  url(transform: {
                    width: 696,
                    resizeStrategy: FIT,
                  })
                  description
                }
                imageMobile {
                  contentType
                  url(transform: {
                    width: 696,
                    resizeStrategy: FIT,
                  })
                  description
                }
                subCopy
                subCopyMobile
                buttons: buttonsCollection(limit: 3) {
                  items {
                    sys {
                      id
                    }
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    email
                    buttonLink
                    buttonEventLabel
                    openInNewTab
                  }
                }
                sectionTheme: theme
                isLinkedInBanner
              }
              ... on PageOverviewBanner {
                title
                titleMobile
                subTitle
                subText
                subTextMobile
                buttons: buttonsCollection(limit: 3) {
                  items {
                    sys {
                      id
                    }
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    buttonLink
                    email
                    openInNewTab
                    hideOnLoggedIn
                  }
                }
                theme
                image {
                  contentType
                  url
                  description
                }
                imageMobile {
                  contentType
                  url
                  description
                }
                loginTitle
                loginMobileTitle
                loginSubtext
                widget
              }
              ... on EventList {
                title
                featured
                eventList: eventListCollection(limit: 4) {
                  items {
                    sys { 
                      id 
                    }
                    title
                    slug
                    image {
                      contentType
                      url(transform: {
                        height: 250,
                        resizeStrategy: FIT,
                      })
                    }
                    startDate
                    endDate
                    address
                    seo {
                      description
                    }
                    tags: tagsCollection(limit: 2) {
                      items {
                        ${tagsAndMarketsQuery()}
                      }
                    }
                  }
                }
              }
              ... on LeadsWrapper {
                title
                theme
                backgroundImage {
                  contentType
                  url
                  description
                }
                form {
                  sys {
                    id
                  }
                  title
                  subText
                  button {
                    primaryButtonText
                    secondaryButtonText
                    function
                    color
                    buttonLink
                    openInNewTab
                  }
                  formFieldList: formFieldListCollection(
                    preview: ${isPreviewMode}
                    limit: 7
                  ) {
                    items {
                      ... on FormField {
                        name
                        label
                        placeholder
                        isMandatory
                        type
                        width
                        mandatoryCheckErrorMessage
                        customErrorMessage
                        dropdownOptions
                      }
                    }
                  }
                }
                valuePropositionList: valuePropositionListCollection(
                  preview: ${isPreviewMode}
                  limit: 3
                ) {
                  items {
                    sys {
                      id
                    }
                    title
                    contentType
                    url
                    description
                  }
                }
                customerLogoTitle
                customerLogoList: customerLogoListCollection(
                  preview: ${isPreviewMode}
                  limit: 8
                ) {
                  items {
                    sys {
                      id
                    }
                    contentType
                    url
                    description
                  }
                }
                zapierHookId
              }
            }
          }
        }
      }
    }
  `;

  const _slug = `/${articleTypeName}${slug ? `/${slug}` : ''}`;
  const response = await callContentful(minifyQuery(query), isPreviewMode, _slug, 'tag.fetchers.getTag');
  const tagData = response.data?.tagCollection.items?.filter(Boolean)?.[0];
  const sectionItems = tagData?.sectionList?.items?.filter(Boolean);

  const foundHighlightSection: IHighlightSection = sectionItems?.find(
    (section: any) => section.__typename === ContentTypeName.HighlightSection,
  );

  const foundThumbnailSection: IThumbnailSection = sectionItems?.find(
    (section: any) => section.__typename === ContentTypeName.ThumbnailSection,
  );

  const foundThumbnailCarousel: IThumbnailCarousel[] = sectionItems?.filter(
    (section: any) => section.__typename === ContentTypeName.ThumbnailCarousel,
  );

  const { hasEvents, hasArticles } = needEventOrArticleData({
    foundHighlightSection,
    foundThumbnailSection,
    foundThumbnailCarousel,
  });

  const allEvents = hasEvents ? allEventsList || [] : [];
  const allArticles = hasArticles ? allArticlesList || [] : [];

  if (foundHighlightSection) {
    const filteredTags = foundHighlightSection.tags?.items?.filter(Boolean);
    let highlightSectionThumbnails: ThumbnailProps[] = [];

    if (!filteredTags || filteredTags.length === 0) {
      highlightSectionThumbnails = utils_getThumbnailsByTag({
        isFeatured: foundHighlightSection.displayingLogic === HighlightDisplayLogic.Featured,
        articles: allArticles,
        slug: tagData.slug,
        type: tagData.type,
      });
    } else {
      highlightSectionThumbnails = utils_getThumbnailsByTagList({
        isFeatured: foundHighlightSection.displayingLogic === HighlightDisplayLogic.Featured,
        articles: allArticles,
        tags: filteredTags,
      });
    }

    foundHighlightSection.thumbnails = highlightSectionThumbnails.slice(0, 4);
  }

  if (foundThumbnailCarousel.length > 0) {
    foundThumbnailCarousel.map((item) => {
      const el =
        item.itemType === SectionItemType.Event
          ? utils_getEventThumbnailsByDate({
              events: allEvents,
              slug: item.tag.slug,
              type: item.tag.type,
            })
          : utils_getThumbnailsByTag({
              articles: allArticles,
              type: item.tag.type,
              slug: item.tag.slug,
            });
      item.thumbnails = el.slice(0, THUMBNAIL_CAROUSEL_MAX_ITEMS);
    });
  }

  if (foundThumbnailSection) {
    foundThumbnailSection.latestData = utils_getTagPageThumbnailSectionData({
      foundThumbnailSection,
      foundHighlightSection,
      allThumbnailList: allArticles,
      allEventList: allEvents,
      tagDataType: tagData.type,
      tagDataSlug: tagData.slug,
    });

    foundThumbnailSection.foundHighlightSection = foundHighlightSection || null;
    foundThumbnailSection.pageType = PageType.TagPage;
    foundThumbnailSection.tagDataType = tagData.type;
    foundThumbnailSection.tagDataSlug = tagData.slug;
  }

  return tagData ? tagDataModify(tagData) : null;
};

type GetAllTagUrlInfo = (args: { isPreviewMode: boolean }) => Promise<ISlugItem[]> | null;

export const getAllTagUrlInfo: GetAllTagUrlInfo = async ({ isPreviewMode }) => {
  const query = `
    {
      tagCollection(
        preview: ${isPreviewMode}
        where: {
          hide_not: true
        }
      ) {
        items {
          type
          slug
        }
      }
    }
  `;

  const response = await callContentful(query, isPreviewMode); // PARENT FUNC IS DEPRECATED

  return response.data?.tagCollection?.items?.filter(Boolean) || null;
};

export const getInsightsRssInfo = async (): Promise<ISEOSimpleFields | null> => {
  const query = `
    {
      tagCollection(
        preview: false
        where: {
          type: "${ArticleTypeName.insights}"
          slug_exists: false
        }
        limit: 1
      ) {
        items {
          seo {
            title
            description
            ogUrl
          }
        }
      }
    }
  `;

  const response = await callContentful(query, false, '/rss', 'tag.fetchers.getInsightsRssInfo');
  return response.data?.tagCollection.items?.filter(Boolean)?.[0]?.seo || null;
};
