import { TrackingWidget } from '@/components';
import { CookieKey, UNKNOWN_COUNTRY } from '@/constants';
import { useCalculateHeight, useGeo, useGlobalState } from '@/hooks';
import { type IFooter, type IHeader, PMCAdType, SectionWrapperVariant } from '@/interfaces';
import { PMTAdType } from '@/modules/pmt/constants';
import { isEU, showCookieBannerPopup } from '@/utils';
import Cookies from 'js-cookie';
import dynamic from 'next/dynamic';
import { type ReactChild, useCallback, useEffect, useState } from 'react';
import CCPA from './CCPA';
import CookieBanner from './CookieBanner';
import Footer from './Footer';
import Header from './Header';
import { CustomPMCAdsense, CustomPMTAdsense, LayoutWrapper, Main, StickyCollapseMenu } from './Layout.styles';

const HeaderBanner = dynamic(async () => import('./HeaderBanner'), { ssr: false });

interface LayoutProps {
  children: ReactChild;
  footer?: IFooter;
  header?: IHeader;
  onlyLogo?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, footer, header, onlyLogo }) => {
  const { isHomepage, isErrorPage, isPrivacyPage, isTrackingPage, isMyParcelsPage, isLoggedIn, userInfo } =
    useGlobalState();
  const { geoInfo } = useGeo();
  const [hasCookieBanner, setCookieBanner] = useState(false);
  const [showTrackingWidget, setShowTrackingWidget] = useState(false);
  const hideCookieAndQC = isErrorPage || isPrivacyPage || isTrackingPage;
  const isBasicDetails = !!userInfo?.firstName && !!userInfo.lastName && !!userInfo.companyName;

  useEffect(() => {
    const _hasCookieBanner =
      !hideCookieAndQC &&
      showCookieBannerPopup() &&
      geoInfo.country === UNKNOWN_COUNTRY && //if no geoInfo, will show Quantcast
      !isEU(geoInfo);
    setCookieBanner(_hasCookieBanner);
  }, [geoInfo, isErrorPage, isPrivacyPage, isTrackingPage]);

  useEffect(() => {
    const browserUuid = Cookies.get(CookieKey.BrowserUuid);
    const isUnverified = !!browserUuid && !isLoggedIn;

    if (!isUnverified && isHomepage && (!isLoggedIn || isBasicDetails)) {
      setShowTrackingWidget(true);
    } else {
      setShowTrackingWidget(false);
    }
  }, [isHomepage, isLoggedIn, userInfo]);

  useEffect(() => {
    const CCPA = document.getElementById('pm_ccpa_container');

    const observer = new MutationObserver((mutationsList) => {
      // This callback function will be called whenever there are changes in the DOM
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          const firstAddedNode = mutation.addedNodes[0] as HTMLElement;
          const offsetHeight = firstAddedNode.offsetHeight;
          if (offsetHeight > 0) setCookieBanner(false);
        }
      });
    });

    if (CCPA) observer.observe(CCPA, { childList: true });
  }, []);

  const renderQuantcast = () => {
    if (hideCookieAndQC) {
      return null;
    }
    return <CCPA />;
  };

  const renderCookie = () => {
    if (!hasCookieBanner || hideCookieAndQC) {
      return null;
    }
    return <CookieBanner />;
  };

  const top = useCalculateHeight(['header', 'header-banner']);

  const renderPMCAdsense = useCallback(() => {
    if (isTrackingPage || isMyParcelsPage) return null;

    return <CustomPMCAdsense variant={PMCAdType.TOP_OF_PMC_PAGE} id='ads-top-of-page' className='ads-top-of-page' />;
  }, [isTrackingPage, isMyParcelsPage]);

  const renderPMTAdsense = useCallback(() => {
    if (!isMyParcelsPage) return null;

    return <CustomPMTAdsense variant={PMTAdType.PMT_HEADER} id='ads-pmt-header' className='ads-pmt-header' />;
  }, [isMyParcelsPage]);

  return (
    <LayoutWrapper id='layout'>
      {renderPMCAdsense()}
      <Header {...header} onlyLogo={onlyLogo} />
      <StickyCollapseMenu id='sticky-collapse-menu' top={top} />
      {!onlyLogo && !isErrorPage && <HeaderBanner />}
      {renderPMTAdsense()}
      {showTrackingWidget && <TrackingWidget variant={SectionWrapperVariant.StickyDisplay} />}
      <Main id='main'>{children}</Main>
      {!isErrorPage && footer && <Footer {...footer} onlyLogo={onlyLogo} />}
      {renderQuantcast()}
      {renderCookie()}
    </LayoutWrapper>
  );
};

export default Layout;
