import { mapLineItem, mapOrderTracking, mapParcel } from '.';
import type { IShipmentData, IShipmentDataDTO } from '../interfaces';
import {
  getShipmentImportedDate,
  getShipmentIssue,
  getShipmentMilestone,
  getShipmentStage,
  getShipmentTime,
  isEmailSyncShipment,
  isMockCarrierShipment,
  isOpenShipment,
  isSMEShipment,
  transformCustomFields,
} from '../utils';

export const mapShipment = (firestoreDocId: string, shipment: IShipmentDataDTO): IShipmentData => {
  const mapped = <IShipmentData>{
    firestoreDocId,
    searchStatus: shipment.search_status,
    createdAt: getShipmentTime(shipment.created_at),
    updatedAt: getShipmentTime(shipment.updated_at),
    shipmentStatus: shipment.shipment_status,
    shipmentUuid: shipment.parcelperform_id,
    carrierId: shipment.parcel.carrier?.carrier_id,
    parcel: mapParcel(shipment.parcel),
    rawParcel: shipment.parcel,
    shipmentImportedDate: getShipmentImportedDate(shipment),
    subscribeState: shipment.subscribe_state,
    // email_sync output
    orderTracking: mapOrderTracking(shipment.order_tracking),
    expectedDelivery: getShipmentTime(shipment.expected_delivery),
    expectedDeliveryTo: getShipmentTime(shipment.expected_delivery_to),
    additionalInfo: transformCustomFields(shipment.custom_fields), // map custom_fields to additionalInfo to match with the requirement
    shipmentValue: shipment.shipment_value,
    totalShippingCost: shipment.total_shipping_cost,
    lineItems: shipment.line_items?.map(mapLineItem),
    carrierReference: shipment.carrier_reference,
    lastSearched: getShipmentTime(shipment.last_searched),
    estimatedDeliveryDate: getShipmentTime(shipment.estimated_delivery_date),
    isDeleted: shipment.is_deleted,
    parcelUserTag: shipment.parcel_user_tag,
    merchantRef: shipment.merchant_ref?.path,
  };

  // Please don't change order of these 3, a computed value is dependent on the previous computed value
  mapped.hasIssue = getShipmentIssue(mapped);
  mapped.milestone = getShipmentMilestone(mapped);
  mapped.stage = getShipmentStage(mapped);
  // End
  mapped.isSMEShipment = isSMEShipment(mapped);
  mapped.isOpenShipment = isOpenShipment(mapped);
  mapped.isEmailSyncShipment = isEmailSyncShipment(mapped);
  mapped.isMockCarrierShipment = isMockCarrierShipment(mapped);

  return mapped;
};
