import { WordCloudMapping } from '@/constants';
import { type IWordCloud, WordCloudLayout } from '@/interfaces';
import {
  WordCloudBackgroundContainer,
  WordCloudSectionHeader,
  WordCloudSectionSubText,
  WordCloudSectionTitle,
  WordCloudSectionWrapper,
} from './WordCloud.styles';

const WordCloud: React.FC<IWordCloud> = ({ headingsAlignment, title, layout, subText, buttonLink, wordBubbles }) => {
  const props = { wordBubbles, buttonLink };

  const renderLayout = () => {
    const WordCloudLayoutComponent = WordCloudMapping[layout || WordCloudLayout.Slider];
    return <WordCloudLayoutComponent {...props} />;
  };

  const renderSectionSubText = (subText?: IWordCloud['subText'], isDesktop = false) => {
    if (!subText) return null;

    return (
      <WordCloudSectionSubText alignment={headingsAlignment} isDesktop={isDesktop}>
        {subText}
      </WordCloudSectionSubText>
    );
  };

  return (
    <WordCloudBackgroundContainer>
      <WordCloudSectionWrapper>
        <WordCloudSectionHeader>
          <WordCloudSectionTitle alignment={headingsAlignment}>{title}</WordCloudSectionTitle>
          {renderSectionSubText(subText)}
          {renderSectionSubText(subText, true)}
        </WordCloudSectionHeader>
        {renderLayout()}
      </WordCloudSectionWrapper>
    </WordCloudBackgroundContainer>
  );
};

export default WordCloud;
