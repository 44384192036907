import styled from 'styled-components';

import Typography from '@/components/Typography';

import { TextStyles, theme } from '@/constants';
import type { BreakpointNames } from '@/interfaces';

const styledSectionTitle = `
  color: ${theme.colors.highEmphasisBlack};
  position: relative;
  margin-bottom: ${theme.spacing[16]};
  word-break: break-word;

  ::after {
    content: '';
    position: relative;
    display: block;
    margin-top: 1px;
    width: 24px;
    height: 3px;
    background: ${theme.colors.darkOrange};
  }
`;

// default desktop/mobile
// styling: H2/H2, bottom: 28/20
export const SectionTitle = styled(Typography).attrs({
  variant: TextStyles['Heading 2'],
  className: 'section-title',
})`
  ${styledSectionTitle}

  ${({ theme, breakpoint, isMobile }) => `
    ${theme.mediaBreakpointUp[(breakpoint || 'md') as BreakpointNames]} {
      ${isMobile ? 'display: none;' : ''}
      margin-bottom: ${theme.spacing[24]};
    }
  `}
`;

// small desktop/mobile
// styling: H2/H3, bottom: 24/20
export const SectionTitleSmall = styled(SectionTitle)`
  ${({ theme, breakpoint }) => `
    ${theme.mediaBreakpointUp[(breakpoint || 'md') as BreakpointNames]} {
      margin-bottom: ${theme.spacing[20]};
    }
  `}
`;
