import { CookieKey, DelayTime, ON_PAGE_DURATION_BY_SECOND } from '@/constants';
import { useGlobalState } from '@/hooks';
import { pmtQueryParams } from '@/modules/pmt/constants';
import { useEmailSyncContext, useMyParcelsContext, useShipmentDetailsContext } from '@/modules/pmt/context';
import { PmtSignUpStep } from '@/modules/pmt/interfaces';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const DEFAULT_TIMERS = {
  newUserTimer: [DelayTime['10s'], DelayTime['30s'], DelayTime['2mins']],
  returnUserTimer: [DelayTime['20s'], DelayTime['2mins']],
  smeNotiTimer: [DelayTime['30s'], DelayTime['2mins']],
};

interface IUseTrackingOnboardingPopup {
  showRequestLoginDialog: boolean;
  setIsFromRequestLoginDialog: (isFromRequestLoginDialog: boolean) => void;
}

export const useTrackingOnboardingPopUp = ({
  showRequestLoginDialog,
  setIsFromRequestLoginDialog,
}: IUseTrackingOnboardingPopup) => {
  const { query } = useRouter();
  const {
    isLoggedIn,
    showPMTPopUp,
    pmtSignUpStep,
    setShowPMTPopUp,
    setIsFromGetNotifiedBtn,
    setIsFromEmailSyncBtn,
    setIsFromDelayedShipmentBtn,
  } = useGlobalState();
  const { showDeleteShipment } = useShipmentDetailsContext();
  const { isEmailSyncAllowed } = useEmailSyncContext();
  const { pmtEmailSyncOnboardingPopUp, pmtOnboardingPopUp } = useMyParcelsContext();
  const pmtPopUp = isEmailSyncAllowed ? pmtEmailSyncOnboardingPopUp : pmtOnboardingPopUp;

  const isSignUpScreen = pmtSignUpStep === PmtSignUpStep.SignUp;

  const [timers, setTimers] = useState(() => {
    if (query['isSME'] === 'true') {
      return DEFAULT_TIMERS.smeNotiTimer;
    }
    if (isSignUpScreen) {
      const isReturner = Cookies.get(CookieKey.IsPmtReturner);
      return isReturner ? DEFAULT_TIMERS.returnUserTimer : DEFAULT_TIMERS.newUserTimer;
    }
    return [];
  });
  const [isPaused, setIsPaused] = useState<boolean>(false);

  useEffect(() => {
    if (isLoggedIn) setTimers([]);
  }, [isLoggedIn]);

  const willShowPopUp =
    !query[pmtQueryParams.IsFromEmsLandingPage] &&
    !isLoggedIn &&
    !isPaused &&
    !showPMTPopUp &&
    !showDeleteShipment &&
    !showRequestLoginDialog &&
    pmtPopUp &&
    timers.length &&
    pmtSignUpStep !== PmtSignUpStep.Success;

  const delayTime = timers[0];

  useEffect(() => {
    if (!willShowPopUp) return;

    const timer = setTimeout(() => {
      setIsFromGetNotifiedBtn(false);
      setIsFromEmailSyncBtn(false);
      setIsFromDelayedShipmentBtn(false);
      setIsFromRequestLoginDialog(false);
      setShowPMTPopUp(true);
    }, delayTime * 1000);

    if (!isSignUpScreen) {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
    // Missing deps are state setters, which aren't needed here
  }, [isSignUpScreen, delayTime, willShowPopUp, pmtSignUpStep]);

  useEffect(() => {
    if (Cookies.get(CookieKey.IsPmtReturner)) return;

    const pmtPageTimerValue = Cookies.get(CookieKey.OnPmtPageTimer);
    let onPmtPageTimer = Number.isNaN(pmtPageTimerValue) ? 0 : +pmtPageTimerValue;

    const interval = setInterval(() => {
      Cookies.set(CookieKey.OnPmtPageTimer, ++onPmtPageTimer);
      if (onPmtPageTimer >= ON_PAGE_DURATION_BY_SECOND) {
        Cookies.set(CookieKey.IsPmtReturner, true);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const closePopUp = () => {
    setIsFromGetNotifiedBtn(false);
    setIsFromEmailSyncBtn(false);
    setIsFromRequestLoginDialog(false);
    setShowPMTPopUp(false);

    if (!isSignUpScreen) return;

    setTimers((prev) => prev.slice(1));
  };

  return {
    delayTime,
    closePopUp,
    pauseOpenOnboardingPopup: setIsPaused,
  };
};
