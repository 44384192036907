import { Container, TrackingWidget } from '@/components';
import { CookieKey } from '@/constants';
import { useGlobalState } from '@/hooks';
import { type IPageOverviewBanner, SectionThemeKey, SectionWrapperVariant } from '@/interfaces';
import { gtmClickOverviewBannerSignups, replaceHighlightString } from '@/utils';
import Cookies from 'js-cookie';
import type React from 'react';
import { useEffect, useState } from 'react';
import ButtonGroup from '../../ButtonGroup';
import GraphicImg from '../../GraphicImg';
import WidgetTextSection from '../WidgetTextSection';
import {
  BannerWithTrackingWidgetWrapper,
  ButtonGroupWrapper,
  TrackingWidgetMobileWrapper,
  TrackingWidgetWrapper,
} from './BannerWithTrackingWidget.styles';

const DEFAULT_LOGIN_TITLE = 'Welcome back!';

const BannerWithTrackingWidget: React.FC<IPageOverviewBanner> = ({
  title,
  titleMobile,
  subText,
  subTextMobile,
  loginTitle,
  loginMobileTitle,
  loginSubtext,
  priority,
  buttons,
  image,
  imageMobile,
  theme,
}) => {
  const { isLoggedIn, userInfo } = useGlobalState();

  const [isUnverified, setIsUnverified] = useState(false);

  const isBasicDetails = !!userInfo?.firstName && !!userInfo.lastName && !!userInfo.companyName;

  useEffect(() => {
    const browserUuid = Cookies.get(CookieKey.BrowserUuid);
    if (browserUuid) {
      setIsUnverified(!!browserUuid && !isLoggedIn);
    }
  }, [userInfo]);

  const formattedLoginTitle = loginTitle && replaceHighlightString(loginTitle, 'username', userInfo?.firstName || '');

  const formattedMobileLoginTitle =
    loginMobileTitle && replaceHighlightString(loginMobileTitle, 'username', userInfo?.firstName || '');

  const renderTextSection = () => {
    let textObject = {
      mobileTitle: titleMobile,
      desktopTitle: title,
      mobileSubText: subTextMobile ?? '',
      desktopSubText: subText ?? '',
    };

    if (isLoggedIn) {
      textObject = {
        mobileTitle: userInfo?.firstName && formattedMobileLoginTitle ? formattedMobileLoginTitle : DEFAULT_LOGIN_TITLE,
        desktopTitle: userInfo?.firstName && formattedLoginTitle ? formattedLoginTitle : DEFAULT_LOGIN_TITLE,
        mobileSubText: loginSubtext ?? '',
        desktopSubText: loginSubtext ?? '',
      };
    }

    return <WidgetTextSection {...textObject} />;
  };

  const renderTrackingWidget = () => {
    const shouldRenderBlockDisplay = isUnverified;
    const shouldRenderColumnDisplay = isLoggedIn && !isBasicDetails;

    if (!shouldRenderBlockDisplay && !shouldRenderColumnDisplay) return null;

    const variant = shouldRenderBlockDisplay ? SectionWrapperVariant.BlockDisplay : SectionWrapperVariant.ColumnDisplay;

    return (
      <TrackingWidgetMobileWrapper>
        <TrackingWidget variant={variant} />
      </TrackingWidgetMobileWrapper>
    );
  };

  return (
    <>
      <BannerWithTrackingWidgetWrapper sectionTheme={theme || SectionThemeKey.Light}>
        <GraphicImg includedWigdet={true} image={image} imageMobile={imageMobile} priority={priority} />
        <Container style={{ position: 'relative' }}>
          {renderTextSection()}
          {!!buttons?.length && (
            <ButtonGroupWrapper>
              <ButtonGroup
                buttons={buttons}
                buttonClick={() => {
                  gtmClickOverviewBannerSignups();
                }}
              />
            </ButtonGroupWrapper>
          )}
        </Container>
        <TrackingWidgetWrapper>
          <TrackingWidget variant={SectionWrapperVariant.RowDisplay} />
        </TrackingWidgetWrapper>
      </BannerWithTrackingWidgetWrapper>
      {renderTrackingWidget()}
    </>
  );
};

export default BannerWithTrackingWidget;
