import { ParcelSearchesSource } from '@/constants';
import { useGlobalState } from '@/hooks';
import { useRecaptchaContext } from '@/modules/pmt/context';
import { checkPmtEmailQueryValid, checkPmtSingleQueryParamValid } from '@/modules/pmt/utils';
import { getPmtLangHref, gtmClickEmailCta, gtmParcelSearches, trackingParamExtractionModify } from '@/utils';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { ReCaptchaV3Action } from '../constants';
import { useFetchShipments } from './useFetchShipments';

export const useSearchShipments = () => {
  const router = useRouter();
  const { firestoreUuid, pmtCurrentLanguageCode } = useGlobalState();
  const { fetchShipments } = useFetchShipments();
  const { isV3Loaded, checkWithReCaptchaV3 } = useRecaptchaContext();

  useEffect(() => {
    (async () => {
      if (!isV3Loaded) return;
      const { shipmentId, pParcelIds, pParcelId } = router.query;
      if ((!pParcelIds && !shipmentId && !pParcelId) || !firestoreUuid) return;

      switch (true) {
        case checkPmtEmailQueryValid(router.query, shipmentId): {
          await checkWithReCaptchaV3({
            action: ReCaptchaV3Action.TrackingFromEmail,
            callback: () => {
              fetchShipments([shipmentId as string]);
              gtmClickEmailCta();
            },
          });

          break;
        }

        case checkPmtSingleQueryParamValid(router.query, pParcelIds): {
          await checkWithReCaptchaV3({
            action: ReCaptchaV3Action.TrackingFromAMP,
            callback: () => {
              const parcelIds = trackingParamExtractionModify(pParcelIds as string);
              fetchShipments(parcelIds);
              gtmParcelSearches(parcelIds.length, ParcelSearchesSource.AMP);
              void router.push(getPmtLangHref(pmtCurrentLanguageCode), undefined, { shallow: true });
            },
          });

          break;
        }

        case checkPmtSingleQueryParamValid(router.query, pParcelId as string): {
          await checkWithReCaptchaV3({
            action: ReCaptchaV3Action.TrackingFromPMC,
            callback: () => {
              fetchShipments(trackingParamExtractionModify(pParcelId as string));
              // PMC only allow to search for 1 shipment
              gtmParcelSearches(1, ParcelSearchesSource.PMC);
              void router.push(getPmtLangHref(pmtCurrentLanguageCode), undefined, { shallow: true });
            },
          });
          break;
        }
        default:
          break;
      }
    })();
  }, [firestoreUuid, fetchShipments, router.push, router.query, pmtCurrentLanguageCode, isV3Loaded]);
};
