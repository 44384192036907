import { Breadcrumbs, Container, PageOverviewBanner, RightSection } from '@/components';
import { ContentTypeName } from '@/constants';
import type { IMarket, IRightPanel } from '@/interfaces';
import { capitalize } from '@/utils';
import SectionListRenderer from '../SectionListRenderer';
import { LeftSection, SectionListWrapper } from '../SectionListRenderer.styles';
import { CountryFlag, Globe, StyledHeaderSection } from './MarketPage.styles';

interface IMarketPageRendererProps {
  sectionList?: any[];
  headerSection: Partial<IMarket>;
  rightSectionList: Array<IRightPanel>;
}

const FULL_WIDTH_SECTION_LIST = [ContentTypeName.SeoSection, ContentTypeName.LeadsWrapper];

const MarketPageRenderer: React.FC<IMarketPageRendererProps> = ({
  sectionList = [],
  headerSection,
  rightSectionList,
}) => {
  const { marketName, countryCode, breadcrumbsTheme, seoDescription } = headerSection;

  const foundPageOverviewBanner = sectionList.find(
    (section: any) => capitalize(section.__typename) === ContentTypeName.PageOverviewBanner,
  );

  const sections = sectionList.reduce<{
    fullWidthSectionList: any[];
    leftSectionList: any[];
  }>(
    (result, section) => {
      const contentType = capitalize(section.__typename) as ContentTypeName;
      const isIncludedPageOverViewBanner = contentType === ContentTypeName.PageOverviewBanner;
      if (isIncludedPageOverViewBanner) {
        return result;
      }

      const isFullWidthSection = FULL_WIDTH_SECTION_LIST.includes(contentType);

      if (isFullWidthSection) {
        result.fullWidthSectionList.push(section);
      } else {
        result.leftSectionList.push(section);
      }

      return result;
    },
    {
      fullWidthSectionList: [],
      leftSectionList: [],
    },
  );

  const renderMarketIcon = () => {
    const isRegion = !countryCode;
    if (isRegion) return <Globe src='/images/icons/public.svg' alt='globe' />;
    return <CountryFlag countryCode={countryCode} alt={marketName} />;
  };

  return (
    <>
      {foundPageOverviewBanner && (
        <PageOverviewBanner
          breadcrumbs={{
            title: marketName,
            breadcrumbsTheme,
          }}
          priority
          {...foundPageOverviewBanner}
        />
      )}
      <Container>
        <SectionListWrapper>
          <LeftSection>
            {!foundPageOverviewBanner && <Breadcrumbs title={marketName} breadcrumbsTheme={breadcrumbsTheme} />}
            <StyledHeaderSection title={marketName} iconComponent={renderMarketIcon} description={seoDescription} />
            <SectionListRenderer sectionList={sections.leftSectionList} />
          </LeftSection>
          {rightSectionList && <RightSection rightSectionList={rightSectionList} />}
        </SectionListWrapper>
      </Container>
      <SectionListRenderer sectionList={sections.fullWidthSectionList} pageTitle={marketName} />
    </>
  );
};

export default MarketPageRenderer;
