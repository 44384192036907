import { Breadcrumbs, Container, PageOverviewBanner, RightSection } from '@/components';
import { LeftSection, SectionListWrapper, Title, TitleWrapper } from '../SectionListRenderer.styles';

import { ContentTypeName } from '@/constants';
import type { IEvent, IRightPanel, SectionThemeKey } from '@/interfaces';
import { capitalize } from '@/utils';

import BrowsingIcon from '../../../public/images/icons/browsing.svg';
import SectionListRenderer from '../SectionListRenderer';

interface IPermutationPageRendererProps {
  sectionList?: any[];
  title?: string;
  featuredEventList?: IEvent[] | null;
  rightSectionList: Array<IRightPanel>;
  breadcrumbsTheme?: SectionThemeKey;
}

const PermutationPageRenderer: React.FC<IPermutationPageRendererProps> = ({
  sectionList = [],
  title = '',
  featuredEventList,
  rightSectionList,
  breadcrumbsTheme,
}) => {
  const foundPageOverviewBanner = sectionList.find(
    (section: any) => capitalize(section.__typename) === ContentTypeName.PageOverviewBanner,
  );

  const FULL_WIDTH_SECTION_LIST = [ContentTypeName.LeadsWrapper, ContentTypeName.SeoSection];

  const leftSectionList: typeof sectionList = [];
  const fullWidthSectionlist: typeof sectionList = [];

  sectionList
    .filter((section) => capitalize(section.__typename) !== ContentTypeName.PageOverviewBanner)
    .forEach((section: any) => {
      const isFullWidthSection = FULL_WIDTH_SECTION_LIST.includes(capitalize(section.__typename) as ContentTypeName);
      if (isFullWidthSection) {
        fullWidthSectionlist.push(section);
        return;
      }

      leftSectionList.push(section);
    });

  return (
    <>
      {foundPageOverviewBanner && (
        <PageOverviewBanner
          priority
          breadcrumbs={{
            title,
            breadcrumbsTheme,
          }}
          {...foundPageOverviewBanner}
        />
      )}
      <Container>
        <SectionListWrapper>
          {!foundPageOverviewBanner && <Breadcrumbs title={title} breadcrumbsTheme={breadcrumbsTheme} />}
          {title !== '' && (
            <TitleWrapper>
              <BrowsingIcon />
              <Title>{title}</Title>
            </TitleWrapper>
          )}
          <LeftSection>
            <SectionListRenderer sectionList={leftSectionList} featuredEventList={featuredEventList} />
          </LeftSection>
          {rightSectionList && <RightSection rightSectionList={rightSectionList} />}
        </SectionListWrapper>
      </Container>
      {fullWidthSectionlist.length > 0 && <SectionListRenderer sectionList={fullWidthSectionlist} />}
    </>
  );
};

export default PermutationPageRenderer;
